var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "text-h5 font-weight-medium primary--text text-none",
    attrs: {
      "text": "",
      "to": "/jobs/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _vm._v(" Create Jobs ")], 1)], 1), _c('div', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": _vm.mdiMagnify,
      "dense": "",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchJobs,
      callback: function ($$v) {
        _vm.searchJobs = $$v;
      },
      expression: "searchJobs"
    }
  })], 1)]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.jobHeaders,
      "items": _vm.jobs,
      "loading": _vm.customerJobsLoading,
      "search": _vm.searchJobs,
      "sort-by": "date"
    },
    on: {
      "click:row": _vm.navigateToJobDetail
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-none text-body-1",
          attrs: {
            "text": ""
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.created",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")])];
      }
    }, {
      key: "item.expiry",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatDate(item.expiry)) + " ")])];
      }
    }, {
      key: "item.archive",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.archiveJob(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "danger"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiPackageDown) + " ")])], 1)];
      }
    }, {
      key: "item.invaction",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.inviteCandidate(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")])], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }