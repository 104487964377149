<template>
  <v-card outlined elevation="0" class="pa-0" rounded="lg">
    <v-card-title class="justify-space-between">
      <div class="d-flex">
        <v-btn
          class="text-h5 font-weight-medium primary--text text-none"
          text
          to="/jobs/create"
        >
          <v-icon left large>
            {{ mdiPlus }}
          </v-icon>
          Create Jobs
        </v-btn>
      </div>
      <div>
        <v-text-field
          outlined
          v-model="searchJobs"
          :prepend-inner-icon="mdiMagnify"
          dense
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="jobHeaders"
        :items="jobs"
        @click:row="navigateToJobDetail"
        :loading="customerJobsLoading"
        :search="searchJobs"
        sort-by="date"
      >
        <template v-slot:item.name="{ item }">
          <div text class="text-none text-body-1">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.created="{ item }">
          <div>
            {{ formatDate(item.created) }}
          </div>
        </template>
        <template v-slot:item.expiry="{ item }">
          <div>
            {{ formatDate(item.expiry) }}
            <!-- {{ item.date }} -->
          </div>
        </template>
        <template v-slot:item.archive="{ item }">
          <v-btn icon @click.stop="archiveJob(item)">
            <v-icon color="danger">
              {{ mdiPackageDown }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.invaction="{ item }">
          <v-btn @click.stop="inviteCandidate(item)" color="primary" text>
            <v-icon icon color="primary" class="">
              {{ mdiCloudUpload }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPlus, mdiPackageDown, mdiCloudUpload, mdiMagnify } from '@mdi/js'
import moment from 'moment'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { ApiCallStatus } from '@/utils/api'
export default {
  data() {
    return {
      mdiPlus,
      mdiMagnify,
      mdiPackageDown,
      mdiCloudUpload,
      jobHeaders: [
        {
          text: 'Job Title',
          value: 'name',
        },
        {
          text: 'Posted On',
          value: 'created',
          sort: (a, b) => b - a,
        },
        {
          text: 'Applicants',
          value: 'applicants',
          align: 'center',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Expiry',
          value: 'expiry',
        },
        {
          text: 'Archive',
          value: 'archive',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Invite to Assessment',
          value: 'invaction',
          align: 'center',
          sortable: false,
        },
      ],
      jobs: [],
      emptyFetchTimeout: null,
      customerJobsLoading: true,
      searchJobs: '',
    }
  },
  props: {},
  computed: {
    ...mapState('recruiter', ['recruiter']),
    ...mapState('jobs', ['allJobs', 'fetchAllJobsStatus']),
    ...mapState('customer', ['customer']),
  },
  components: {},
  methods: {
    ...mapActions('recruiter', ['fetchRecruiterProfile']),
    ...mapActions('jobs', ['fetchCustomerAllJobs']),
    ...mapMutations('jobs', ['setCurrentlyViewingJob']),
    ...mapActions('notifs', ['addNotif']),
    ...mapActions('customer', ['fetchCustomer']),
    archiveJob() {
      this.addNotif({
        text: 'Will be available soon!',
        type: 'info',
      })
    },
    inviteCandidate(item) {
      this.$router.push({
        path: `/jobs/${item.url}`,
        query: {
          invite: true,
        },
      })
    },
    navigateToJobDetail(item) {
      const clicked = this.allJobs.filter((job) => job.getId() === item.id)
      console.log(`setCurrentlyViewingJob`, { clicked })
      this.setCurrentlyViewingJob(clicked[0])
      this.$router.push({
        path: `/jobs/${item.url}`,
      })
    },
    formatDate(timep) {
      return moment.unix(timep / 1000).format('DD MMM YY')
    },
    populateJobs() {
      this.fetchCustomerAllJobs().then((res) => {
        // console.log(res?.getjobPreviewList());
        this.jobs = []
        this.allJobs.forEach((jobPreview) => {
          var jobMeta = jobPreview?.getMetaData()?.getJobMeta()
          this.jobs.push({
            id: jobPreview?.getId(),
            name: jobPreview?.getTitle(),
            url: jobPreview?.getUrl(),
            created: jobPreview?.getCreatedOn(),
            location: jobMeta?.getLocationList().join(', '),
            applicants: jobMeta?.getCandidatesApplied() ?? 0,
            expiry: jobMeta?.getExpiryDate(),
            tags: jobPreview?.getMetaData()?.getTagsList(),
          })
        })
        clearTimeout(this.emptyFetchTimeout)
      })
    },
  },
  created() {
    this.populateJobs()
    if (!this.customer) {
      this.fetchCustomer().then((r) => {
        console.log(`customer`, this.customer?.toObject())
      })
    }
    if (!this.recruiter) {
      this.fetchRecruiterProfile((r) => {
        console.log(`recruiter`, this.recruiter?.toObject())
      })
    }
  },
  watch: {
    fetchAllJobsStatus() {
      if (this.fetchAllJobsStatus === ApiCallStatus.SUCCESS) {
        this.customerJobsLoading = false
      } else if (this.fetchAllJobsStatus === ApiCallStatus.ERROR) {
        this.customerJobsLoading = false
        this.$store.dispatch('notifs/addNotif', {
          text: 'Error loading jobs, reload!',
          type: 'error',
        })
      } else {
        this.customerJobsLoading = true
      }
    },
    jobs() {
      if (this.jobs.length <= 0) {
        console.log('empty jobs')
        this.emptyFetchTimeout = setInterval(() => this.populateJobs(), 7500)
      } else {
        clearTimeout(this.emptyFetchTimeout)
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.emptyFetchTimeout)
  },
}
</script>
<style>
tr.empty_row,
tr.empty_row:hover {
  width: 100%;
  cursor: auto;
  background: #fff !important;
}
tr {
  cursor: pointer;
}
</style>
